import { StaticQuery, graphql } from 'gatsby'
import type { PageProps } from 'gatsby'
import React from 'react'

import { SectionContainer, StatePageContainer, StateContent, Footer, Header } from '@alteos/ui'

import Layout from '../components/Layout'
import { IData } from '../types/404'
import { getCtaParams } from '../utils/contentMaps/ctaContent'
import socialLinks from '../utils/socialLinks'

interface IPageNotFound {
  data: IData
}

const mapContent = (data: IData, setPopupState: React.Dispatch<React.SetStateAction<boolean>>) => {
  const header = {
    items: data.headerContent.content?.map((item) => ({
      to: item.ctaTo,
      label: item.ctaLabel,
    })),
  }
  const content = {
    title: data.statusTitle,
    content: data.statusDescription.statusDescription,
    image: data.statusImage,
    ctaButton: getCtaParams(data.mainRedirectCta, setPopupState),
    ctaLink: getCtaParams(data.subRedirectCta, setPopupState),
  }

  const footer = {
    copyright: data.footerContent.copyright.copyright,
    items: data.footerContent.content?.map((item) => getCtaParams(item, setPopupState)),
    socialLinks,
  }

  return {
    header,
    content,
    footer,
  }
}

const PageNotFound = ({ data }: IPageNotFound) => (
  <Layout>
    {({ setPopupState }) => {
      const content = mapContent(data, setPopupState)

      return (
        <StatePageContainer>
          <>
            <div>
              <SectionContainer noPaddingTop noPaddingBottom noGrid dataTest="404">
                <Header isLight={false} />
              </SectionContainer>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <StateContent dataTest="StateContent" {...content.content} />
              </div>
            </div>
            <Footer dataTest="Footer" {...content.footer} />
          </>
        </StatePageContainer>
      )
    }}
  </Layout>
)

const PageWithQuery = (props: PageProps): JSX.Element => (
  <StaticQuery
    query={graphql`
      {
        page: contentfulStatePageContent(slug: { eq: "/nicht-gefunden" }) {
          headerContent {
            content {
              ctaLabel
              ctaTo
            }
          }
          statusTitle
          statusDescription {
            statusDescription
          }
          statusImage {
            file {
              url
            }
          }
          mainRedirectCta {
            ctaTo
            ctaLabel
          }
          subRedirectCta {
            ctaTo
            ctaLabel
          }
          footerContent {
            copyright {
              copyright
            }
            content {
              ctaLabel
              ctaTo
            }
          }
        }
      }
    `}
    render={(data: { page: IData }) => <PageNotFound {...props} data={data.page} />}
  />
)

export default PageWithQuery
